import React from 'react';
import { Radio, Row, Col } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Height extends React.Component {
    onChange = (e) => {
        this.props.cb(parseInt(e.target.value));
    };

    componentDidMount() {}

    render() {
        return (
            <Row>
                <Col span={24}>
                    <RadioGroup onChange={this.onChange} value={this.props.value}>
                        <RadioButton value={37}>37cm</RadioButton>
                        <RadioButton value={76}>76cm</RadioButton>
                        <RadioButton value={90}>90cm</RadioButton>
                        <RadioButton value={107}>107cm</RadioButton>
                    </RadioGroup>
                </Col>
            </Row>
        );
    }
}

export default Height;
