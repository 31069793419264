import React from 'react';
import { Row } from 'antd';
import PubSub from 'pubsub-js';

import Entry from './entry.js';
import Dimension from './dimension.js';
import Height from './height.js';
import Color from './color.js';
import Feature from './feature.js';
import Material from './material.js';

class Controls extends React.Component {
    indexTimer = null;

    constructor() {
        super();
        this.state = {
            length: 260,
            width: 130,
            height: 76,
            cables: 1,
            plugs: 1,
            editing: 0,
            color: '#f2fefb',
            email: null,
            material: 'birch'
        };
    }

    getDefaultConfiguration() {
        return {
            length: 2600,
            width: 1300,
            height: 760,
            gates: 1,
            plugs: 1,
            color: '#f2fefb',
            material: 'birch'
        };
    }

    getConfiguration() {
        return {
            length: this.state.length * 10,
            width: this.state.width * 10,
            height: this.state.height * 10,
            gates: this.state.cables,
            plugs: this.state.plugs,
            color: this.state.color,
            material: this.state.material
        };
    }

    loadConfiguration(data, index) {
        this.setState({
            length: data.length / 10,
            width: data.width / 10,
            height: data.height / 10,
            cables: data.gates,
            plugs: data.plugs,
            index: index,
            color: data.color,
            material: data.material
        });
    }

    componentDidUpdate() {
        PubSub.publishSync('update', this.getConfiguration());
        if (typeof this.state.index !== 'boolean' && typeof this.state.index !== 'undefined') {
            PubSub.publishSync('snapshot.start', {
                index: parseInt(this.state.index)
            });
            if (this.indexTimer) clearTimeout(this.indexTimer);
            this.indexTimer = setTimeout(
                function () {
                    this.setState({ index: false });
                }.bind(this),
                1000
            );
        }
    }

    lengthUpdate(l) {
        var newState = {
            length: l
        };
        if (l < 200 && this.state.cables > 1) {
            newState.cables = 1;
        }
        if (l < 200 && this.state.plugs > 1) {
            newState.plugs = 1;
        }
        this.setState(newState);
        PubSub.publishSync('view', { mode: 'length' });
    }

    widthUpdate(w) {
        this.setState({ width: w });
        PubSub.publishSync('view', { mode: 'width' });
    }

    heightUpdate(h) {
        this.setState({ height: h });
        PubSub.publishSync('view', { mode: 'height' });
    }

    materialUpdate(m) {
        this.setState({ material: m });
        PubSub.publishSync('view', { mode: 'material' });
    }

    colorUpdate(c) {
        this.setState({ color: c });
        PubSub.publishSync('view', { mode: 'color' });
    }

    cablesUpdate(c) {
        var newState = {
            cables: c
        };
        this.setState(newState);
        var VIEW_TOPIC = 'view';
        PubSub.publishSync(VIEW_TOPIC, { mode: 'cables' });
    }

    plugsUpdate(p) {
        var newState = {
            plugs: p
        };
        if (p === 2 && this.state.cables === 1) {
            newState.cables = 2;
        }
        this.setState(newState);
        var VIEW_TOPIC = 'view';
        PubSub.publishSync(VIEW_TOPIC, { mode: 'plugs' });
    }

    componentDidMount() {
        PubSub.subscribe('event', (msg, data) => {
            if (data.name === 'load') {
                this.loadConfiguration(data.item, data.index);
            } else if (data.name === 'cart') {
                PubSub.publishSync('cart.add', this.getConfiguration());
            }
        });
        PubSub.publishSync('update', this.getConfiguration());
    }

    editing(next) {
        var nextEdit = this.state.editing + next;
        if (nextEdit > 6) {
            nextEdit = 6;
        } else if (nextEdit < 0) {
            nextEdit = 0;
        }
        if (this.state.editing !== nextEdit) {
            this.setState({ editing: nextEdit });
        }
    }

    render() {
        return (
            <Row
                id="PlatoCtrl"
                type="flex"
                style={{
                    flexFlow: 'column nowrap',
                    justifyContent: this.props.isMobile ? 'flex-start' : 'space-around',
                    height: '100%',
                    padding: '0em 2em'
                }}
            >
                <Entry name="LENGTH" position="first" cb={this.editing.bind(this)} isActive={this.state.editing === 0} isMobile={this.props.isMobile}>
                    <Dimension isMobile={this.props.isMobile} value={this.state.length} min={100} max={300} cb={this.lengthUpdate.bind(this)} />
                </Entry>

                <Entry name="WIDTH" position="middle" cb={this.editing.bind(this)} isActive={this.state.editing === 1} isMobile={this.props.isMobile}>
                    <Dimension isMobile={this.props.isMobile} value={this.state.width} min={100} max={150} cb={this.widthUpdate.bind(this)} />
                </Entry>

                <Entry name="HEIGHT" position="middle" cb={this.editing.bind(this)} isActive={this.state.editing === 2} isMobile={this.props.isMobile}>
                    <Height isMobile={this.props.isMobile} value={this.state.height} cb={this.heightUpdate.bind(this)} />
                </Entry>

                <Entry name="CABLES" position="middle" cb={this.editing.bind(this)} isActive={this.state.editing === 3} isMobile={this.props.isMobile}>
                    <Feature
                        isMobile={this.props.isMobile}
                        value={this.state.cables}
                        tableLength={this.state.length}
                        disabledFirst={this.state.plugs===2}
                        list={{
                            1: '1 access',
                            2: '2 access'
                        }}
                        cb={this.cablesUpdate.bind(this)}
                    />
                </Entry>

                <Entry name="PLUGS" position="middle" cb={this.editing.bind(this)} isActive={this.state.editing === 4} isMobile={this.props.isMobile}>
                    <Feature
                        isMobile={this.props.isMobile}
                        value={this.state.plugs}
                        tableLength={this.state.length}
                        list={{
                            1: '1 plug kit',
                            2: '2 plug kits'
                        }}
                        cb={this.plugsUpdate.bind(this)}
                    />
                </Entry>

                <Entry name="WOOD" position="middle" cb={this.editing.bind(this)} isActive={this.state.editing === 5} isMobile={this.props.isMobile}>
                    <Material isMobile={this.props.isMobile} value={this.state.material} cb={this.materialUpdate.bind(this)} />
                </Entry>

                <Entry name="LEGS" position="last" cb={this.editing.bind(this)} isActive={this.state.editing === 6} isMobile={this.props.isMobile}>
                    <Color isMobile={this.props.isMobile} value={this.state.color} cb={this.colorUpdate.bind(this)} />
                </Entry>
            </Row>
        );
    }
}

export default Controls;
