import React from 'react';
import { Slider, InputNumber, Row, Col } from 'antd';

class Dimension extends React.Component {
    onChange = (value) => {
        this.props.cb(value);
    };

    componentDidMount() {}

    render() {
        return (
            <Row>
                <Col span={16}>
                    <Slider min={this.props.min} max={this.props.max} step={5} onChange={this.onChange} value={this.props.value} />
                </Col>
                <Col span={7} offset={1}>
                    <InputNumber
                        min={this.props.min}
                        max={this.props.max}
                        step={5}
                        style={{
                            width: '100%'
                        }}
                        value={this.props.value}
                        onChange={this.onChange}
                    />
                </Col>
            </Row>
        );
    }
}

export default Dimension;
