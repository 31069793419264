import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Radio, Row, Col, Tooltip } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Color extends React.Component {
    onChange = (e) => {
        this.props.cb(e.target.value);
    };

    componentDidMount() {}

    render() {
        let c = {
            Cupertino: ['#f2fefb', '#f2eeeb', 'icon-dark'],
            'Menlo Park': ['#5878bc', '#38589c', 'icon-light'],
            'Los Gatos': ['#f20000', '#f20000', 'icon-light'],
            'Venice Beach': ['#fcfe00', '#fcfe00', 'icon-dark'],
            SoMa: ['#727272', '#000000', 'icon-light'],
            Redwood: ['#58b761', '#58b761', 'icon-light'],
            'Mountain View': ['#fb6518', '#fb6518', 'icon-light']
        };
        let rb = [];
        for (let i in c) {
            rb.push(
                <Tooltip placement="top" title={i} key={i}>
                    <RadioButton value={c[i][0]} style={{ backgroundColor: c[i][1] }} className={c[i][2]}>
                        <CheckOutlined />
                    </RadioButton>
                </Tooltip>
            );
        }
        return (
            <Row>
                <Col span={24}>
                    <RadioGroup onChange={this.onChange} value={this.props.value} className="colorpicker">
                        {rb}
                    </RadioGroup>
                </Col>
            </Row>
        );
    }
}

export default Color;
