import React from 'react';
import { Input, Select, Row, Col } from 'antd';
import { CountryRegionData } from '../../countries';

const { Option } = Select;
const opened_country = [
    'BE',
    'EL',
    'LT',
    'PT',
    'BG',
    'ES',
    'LU',
    'RO',
    'CZ',
    'FR',
    'HU',
    'SI',
    'DK',
    'HR',
    'MT',
    'SK',
    'DE',
    'IT',
    'NL',
    'FI',
    'EE',
    'CY',
    'AT',
    'SE',
    'IE',
    'LV',
    'PL',
    'IS',
    'NO',
    'LI',
    'CH'
];

class Address extends React.Component {
    render() {
        const { update, infos, prefix, next } = this.props;

        return (
            <div>
                <Input
                    ref={prefix + 'companyName'}
                    name={prefix + 'companyName'}
                    placeholder="Company / Name"
                    onChange={update}
                    value={infos[prefix + 'companyName']}
                    onPressEnter={next.bind(this)}
                />
                <Input
                    ref={prefix + 'streetAndNumber'}
                    name={prefix + 'streetAndNumber'}
                    placeholder="Address"
                    onChange={update}
                    value={infos[prefix + 'streetAndNumber']}
                    onPressEnter={next.bind(this)}
                />
                <Row>
                    <Col span={8}>
                        <Input
                            ref={prefix + 'postalCode'}
                            name={prefix + 'postalCode'}
                            placeholder="Postal Code"
                            onChange={update}
                            value={infos[prefix + 'postalCode']}
                            onPressEnter={next.bind(this)}
                        />
                    </Col>
                    <Col span={16}>
                        <div style={{ marginLeft: '0.25em' }}>
                            <Input
                                ref={prefix + 'city'}
                                style={{ width: '100%' }}
                                name={prefix + 'city'}
                                placeholder="City"
                                onChange={update}
                                value={infos[prefix + 'city']}
                                onPressEnter={next.bind(this)}
                            />
                        </div>
                    </Col>
                </Row>
                <Select
                    ref={prefix + 'country'}
                    name={prefix + 'country'}
                    value={infos[prefix + 'country']}
                    style={{ width: '100%' }}
                    onChange={update.bind(null, prefix + 'country')}
                    showSearch
                    placeholder="Country"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {CountryRegionData.map(function (item, i) {
                        if (opened_country.includes(item.shortCode)) {
                            return (
                                <Option value={item.shortCode} key={item.shortCode}>
                                    {item.name}
                                </Option>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Select>
            </div>
        );
    }
}

export default Address;
