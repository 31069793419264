import React from 'react';
import PubSub from 'pubsub-js';
import { Base64 } from 'js-base64';
import queryString from 'query-string';
import { PlusCircleFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Drawer, Button, List, Row, Col, Form, ConfigProvider, message } from 'antd';
import Price from './configurator/price.js';

import Contact from './cart/checkout/contact.js';
import BillingShipping from './cart/checkout/billingshipping.js';
import Payment from './cart/checkout/payment.js';
import PaymentMollie from './cart/checkout/paymentmollie.js';

import * as emailjs from 'emailjs-com';

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', padding: '0px 50px' }}>
        <img src="https://media.giphy.com/media/9J7tdYltWyXIY/giphy.gif" alt="?" style={{ width: '100%' }} />
    </div>
);

class Cart extends React.Component {
    snapshotQueue = [];

    constructor() {
        super();

        let cart = [];
        if (global.window !== undefined) {
            const qs = queryString.parse(window.location.hash);
            if (qs.s) {
                cart = this.decodeCart(qs.s);
                setTimeout(() => {
                    this.loadItem(-1);
                }, 0);
            }
        }
        this.state = {
            showDrawer: false,
            showSecondDrawer: false,
            cart: cart,
            infos: {
                shipping_country: 'FR',
                billing_country: 'FR',
                checkout: 0
            },
            index: 0,
            viewerReady: false,
            checkout: null,
            checkoutStep: 0,
            methods: [],
            saveforlater_loading: false
        };
        this.update = this.update.bind(this);
        this.next = this.next.bind(this);
    }

    loadItem(index) {
        if (this.state.viewerReady === true) {
            const { cart } = this.state;
            index = index < 0 ? cart.length + index : index;
            const item = cart[index];
            this.setState({ index });
            if (item) {
                PubSub.publishSync('event', {
                    name: 'load',
                    item: item,
                    index: parseInt(index)
                });
            }
        }
    }

    encodeCart(cart) {
        let val = cart.map((item) => {
            return {
                l: item.length,
                w: item.width,
                h: item.height,
                c: item.gates,
                p: item.plugs,
                q: item.quantity,
                k: item.color,
                m: item.material
            };
        });
        val = JSON.stringify(val);
        return Base64.encode(val);
    }

    decodeCart(b64) {
        let val = Base64.decode(b64);
        val = JSON.parse(val);
        val = Array.isArray(val) ? val : [val];
        return val.map((item) => {
            let q = 1;
            if (item.q) q = item.q;
            return {
                length: item.l,
                width: item.w,
                height: item.h,
                gates: item.c,
                plugs: item.p,
                quantity: q,
                color: item.k,
                material: item.m ?? 'birch'
            };
        });
    }

    update(event, arg) {
        const { infos } = this.state;
        let name = '';
        let value = '';
        if (typeof event.target != 'undefined') {
            // Data From Input
            name = event.target.name;
            value = event.target.value;
            if (event.target.value === undefined) {
                value = event.target.checked;
            }
        } else {
            // Data From Select
            name = event;
            value = arg;
        }
        // Save Info
        infos[name] = value;
        // If Billing, set the same info for shipping
        if (name.startsWith('billing_') || name.startsWith('shipping_')) {
            infos[name + '_modified'] = true;
            if (name.startsWith('shipping_')) {
                let shipping_attr = name.replace('shipping_', 'billing_');
                if (infos[shipping_attr + '_modified'] === undefined) {
                    infos[shipping_attr] = value;
                }
            }
        }

        // Fill Company
        if (name.startsWith('company') && infos['billing_companyName_modified'] === undefined && infos['shipping_companyName_modified'] === undefined) {
            infos.billing_companyName = value;
            infos.shipping_companyName = value;
        }

        // Save State
        infos.checkout = 0;
        if (infos.email && infos.givenName && infos.familyName && infos.company) {
            infos.checkout++;
            if (
                infos.billing_companyName &&
                infos.billing_country &&
                infos.billing_streetAndNumber &&
                infos.billing_postalCode &&
                infos.billing_city &&
                infos.shipping_companyName &&
                infos.shipping_country &&
                infos.shipping_streetAndNumber &&
                infos.shipping_postalCode &&
                infos.shipping_city
            ) {
                infos.checkout++;
                if (infos.payment_method) {
                    infos.checkout++;
                }
            }
        }
        this.setState({ infos });
    }

    next() {
        this.checkoutNext();
    }

    saveSilentlyByEmail(step) {
        let params = JSON.parse(JSON.stringify(this.state.infos));
        params.b64 = this.encodeCart(this.state.cart);
        params.total = this.renderTotalPrice();
        params.quantity = this.getCount();
        params.checkoutStep = step;
        try {
            emailjs.send('mailjet', 'plato_checkout', params, 'user_kKoK3y6ibXON2NxhfHWf0');
        } catch (error) {}
    }

    saveByEmail() {
        let params = JSON.parse(JSON.stringify(this.state.infos));
        params.b64 = this.encodeCart(this.state.cart);
        params.total = this.renderTotalPrice();
        params.quantity = this.getCount();

        if (this.state.saveforlater_loading === false) {
            this.setState({ saveforlater_loading: true });
            emailjs.send('mailjet', 'plato_email', params, 'user_kKoK3y6ibXON2NxhfHWf0').then(
                function (response) {
                    this.setState({ saveforlater_loading: false });
                    message.success('E-mail sent ✌️.');
                    if (!this.state.checkout) {
                        this.closeDrawer();
                        this.closeSecondDrawer();
                    }
                }.bind(this),
                function (err) {
                    this.setState({ saveforlater_loading: false });
                    if (err.status === 400) {
                        message.error('Please enter a valid e-mail.');
                    } else {
                        message.error("Can't send email.");
                    }
                }.bind(this)
            );
        }
    }

    onAddToCart = (e) => {
        this.toggleDrawer();
    };

    componentDidMount() {
        PubSub.subscribe('cart.add', (msg, data) => {
            let { cart } = this.state;
            data.quantity = 1;
            cart.push(data);
            this.setState({ cart });
            this.loadItem(cart.length - 1);
            this.closeDrawer();
            window.location.hash = 's=' + this.encodeCart(cart);
        });
        PubSub.subscribe('snapshot.ready', (msg, data) => {
            let { cart } = this.state;
            if (cart[data.index]) {
                cart[data.index].snapshot = data.data;
            }
            this.setState({ cart });
            // If we have other snapshot to do
            if (this.snapshotQueue.length) {
                this.loadItem(this.snapshotQueue.shift());
            }
        });
        PubSub.subscribe('cart.view', (msg, data) => {
            this.toggleDrawer();
        });
        PubSub.subscribe('viewer.ready', (msg, data) => {
            this.setState({ viewerReady: true });
            for (let x in this.state.cart) {
                this.snapshotQueue.unshift(x);
            }
            this.loadItem(this.snapshotQueue.shift());
        });
    }

    getCount() {
        let count = 0;
        this.state.cart.map((item) => {
            count += item.quantity ? item.quantity : 1;
            return 0;
        });
        return count;
    }

    componentDidUpdate() {
        this.props.updateCount(this.getCount());
    }

    render() {
        const { cart, showDrawer, showSecondDrawer } = this.state;

        return (
            <React.Fragment>
                <Drawer visible={showDrawer} onClose={this.toggleDrawer} className={this.props.isMobile ? 'cart-drawer-mobile' : 'cart-drawer'}>
                    <Row
                        type="flex"
                        style={{
                            height: '100vh',
                            flexDirection: 'column',
                            flexWrap: 'nowrap'
                        }}
                    >
                        <Col className="cart-head">YOUR CART</Col>
                        <Col
                            style={{
                                flexGrow: 1,
                                flexBasis: 1,
                                overflowY: 'auto'
                            }}
                            className="cart-list"
                        >
                            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={cart}
                                    renderItem={(item, index) => (
                                        <List.Item onClick={this.onItemClick.bind(this, index)} data-index={index}>
                                            <List.Item.Meta
                                                className={parseInt(this.state.index) === index ? 'selected' : ''}
                                                avatar={<img className="snapshot" src={this.state.cart[index].snapshot} alt="" />}
                                                title={
                                                    <div>
                                                        <span
                                                            style={{
                                                                float: 'right'
                                                            }}
                                                        >
                                                            {item.quantity * Price.prototype.getPrice(item)}€
                                                        </span>
                                                        Plato #{index + 1}, {Price.prototype.getPrice(item)}€
                                                    </div>
                                                }
                                                description={this.renderDetails(item, index)}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </ConfigProvider>
                            <Row type="flex" style={{ lineHeight: '90px' }}>
                                <Col span={24}>
                                    <Button block={true} type="dashed" icon={<PlusCircleFilled />} onClick={this.onAddToCart}>
                                        Add another Plato
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="cart-footer">
                            <Row type="flex" className="delivery">
                                <Col span={12}>Delivery & Setup</Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    {this.renderDelivery()} €HT
                                </Col>
                            </Row>
                            <Row type="flex" className="price">
                                <Col span={12}>TOTAL</Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    {this.renderTotalPrice()} €HT
                                </Col>
                            </Row>
                            <Row type="flex" style={{ lineHeight: '90px' }}>
                                <Col span={24}>
                                    <Button block={true} type="primary" onClick={this.toggleSecondDrawer.bind(this)}>
                                        PLACE ORDER
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Drawer
                        className={this.props.isMobile ? 'cart-drawer-mobile' : 'cart-drawer'}
                        onClose={this.toggleSecondDrawer.bind(this)}
                        visible={showSecondDrawer}
                    >
                        <Row
                            type="flex"
                            style={{
                                height: '100vh',
                                flexDirection: 'column',
                                flexWrap: 'nowrap'
                            }}
                        >
                            <Col className="cart-head">PLACE ORDER</Col>
                            <Col
                                style={{
                                    flexGrow: 1,
                                    flexBasis: 1
                                }}
                                className="cart-list"
                            >
                                <p>&nbsp;</p>
                                {this.renderSecondDrawerOrder()}
                            </Col>

                            <Col className="cart-footer">{this.renderSecondDrawerButtons()}</Col>
                        </Row>
                    </Drawer>
                </Drawer>
            </React.Fragment>
        );
    }

    checkoutNext() {
        let s = this.state.checkoutStep + 1;
        s = s > this.state.infos.checkout ? this.state.infos.checkout : s;
        this.saveSilentlyByEmail(s);
        this.setState({ checkoutStep: s });
    }

    checkoutPrevious() {
        let s = this.state.checkoutStep - 1;
        s = s < 0 ? 0 : s;
        this.setState({ checkoutStep: s });
    }

    renderSecondDrawerOrder() {
        const { checkoutStep, infos, cart } = this.state;

        return (
            <div className="cart-form">
                <Form id="orderForm">
                    {checkoutStep === 0 && <Contact update={this.update} infos={infos} next={this.next} />}
                    {checkoutStep === 1 && <BillingShipping update={this.update} infos={infos} next={this.next} />}
                    {checkoutStep === 2 && <Payment update={this.update} cart={cart} infos={infos} next={this.next} />}
                    {checkoutStep === 3 && <PaymentMollie update={this.update} cart={cart} infos={infos} />}
                </Form>
            </div>
        );
    }

    renderSecondDrawerButtons() {
        const { checkoutStep, infos, saveforlater_loading } = this.state;
        const { isMobile } = this.props;

        let span = 6;
        if (checkoutStep === 0) {
            span = isMobile ? 10 : 8;
        }
        return (
            <Row type="flex" style={{ lineHeight: '90px' }}>
                <Col span={span}>
                    {checkoutStep === 0 && (
                        <Button
                            block={true}
                            type={infos.checkout > checkoutStep ? 'default' : 'disabled'}
                            loading={saveforlater_loading}
                            onClick={this.saveByEmail.bind(this)}
                        >
                            Get a Quote
                        </Button>
                    )}
                    {checkoutStep > 0 && (
                        <Button block={true} onClick={this.checkoutPrevious.bind(this)}>
                            Previous
                        </Button>
                    )}
                </Col>
                <Col span={23 - span} offset={1}>
                    {checkoutStep === 0 && (
                        <Button block={true} type={infos.checkout > checkoutStep ? 'primary' : 'disabled'} onClick={this.checkoutNext.bind(this)}>
                            Buy Now
                        </Button>
                    )}
                    {checkoutStep > 0 && checkoutStep < 3 && (
                        <Button block={true} type={infos.checkout > checkoutStep ? 'primary' : 'disabled'} onClick={this.checkoutNext.bind(this)}>
                            Next
                        </Button>
                    )}
                </Col>
            </Row>
        );
    }

    renderDetails(item, index) {
        let str = '';
        if (item.gates === 2 && item.plugs === 2) str = '2 gates & 2 plug kits';
        else if (item.gates === 2 && item.plugs === 2) str = '1 gate & 1 plug kit';
        else {
            if (item.gates > 0) {
                str += item.gates + ' gate';
                if (item.gates > 1) str += 's';
            }
            if (str.length > 0) str += ' & ';
            if (item.plugs > 0) {
                str += item.plugs + ' plug kit';
                if (item.plugs > 1) str += 's';
            }
        }
        if (str.length === 0) str = 'no option';

        return (
            <div>
                <div style={{ float: 'right' }}>
                    <Button icon={<MinusOutlined />} size="small" shape="circle" onClick={this.onItemBtnQuantity.bind(this, index, -1)} />
                    &nbsp;&nbsp;
                    <strong>{item.quantity}</strong>
                    &nbsp;&nbsp;
                    <Button icon={<PlusOutlined />} size="small" shape="circle" onClick={this.onItemBtnQuantity.bind(this, index, 1)} />
                </div>
                {item.width} x {item.length} x {item.height}&nbsp;&nbsp;
                <br />
                <span style={{ textTransform: 'capitalize' }}>{item.material}</span>,{str}
                <div style={{ marginTop: '3px' }}>
                    <span
                        className="remove-btn"
                        onClick={this.onRemoveClick.bind(this, index)}
                        onKeyDown={this.onRemoveClick.bind(this, index)}
                        role="button"
                        tabIndex={0}
                    >
                        Remove
                    </span>
                </div>
            </div>
        );
    }

    renderDelivery() {
        return Price.prototype.getDelivery(this.state.cart);
    }

    renderItem(item) {
        return Price.prototype.getPrice(item);
    }

    renderTotalPrice() {
        return Price.prototype.getTotalPrice(this.state.cart);
    }

    toggleDrawer = (e) => {
        const { showDrawer } = this.state;
        this.setState({
            showDrawer: !showDrawer
        });
    };

    closeDrawer = (e) => {
        this.setState({
            showDrawer: false
        });
    };

    toggleSecondDrawer = (e) => {
        const { showSecondDrawer } = this.state;
        this.setState({
            checkoutStep: 0,
            methods: [],
            showSecondDrawer: !showSecondDrawer
        });
    };

    closeSecondDrawer = (e) => {
        this.setState({
            showSecondDrawer: false
        });
    };

    onItemClick = (index, e) => {
        this.loadItem(index);
    };

    onRemoveClick = (index, e) => {
        let { cart } = this.state;
        let rest = cart.splice(index);
        cart = cart.concat(rest.slice(1));

        if (index >= cart.length) index = cart.length - 1;

        this.loadItem(index);
        this.setState({ cart });
        window.location.hash = 's=' + this.encodeCart(cart);
    };

    onItemQuantity = (index, value) => {
        let { cart } = this.state;
        cart[index].quantity = value;
        this.setState({ cart });
        window.location.hash = 's=' + this.encodeCart(cart);
        return false;
    };
    onItemBtnQuantity = (index, add) => {
        let { cart } = this.state;
        if (cart[index].quantity + add > 0) {
            cart[index].quantity += add;
            this.setState({ cart });
            window.location.hash = 's=' + this.encodeCart(cart);
        }
        return false;
    };
}

export default Cart;
