import React from 'react';
import { Radio, Row, Col } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Height extends React.Component {
    onChange = (e) => {
        this.props.cb(e.target.value);
    };

    componentDidMount() {}

    render() {
        return (
            <Row>
                <Col span={24}>
                    <RadioGroup onChange={this.onChange} value={this.props.value}>
                        <RadioButton value={'birch'}>Birch</RadioButton>
                        <RadioButton value={'oak'}>Oak</RadioButton>
                    </RadioGroup>
                </Col>
            </Row>
        );
    }
}

export default Height;
