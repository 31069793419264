import React from 'react';
import { Row, Col } from 'antd';
import PubSub from 'pubsub-js';

class Tips extends React.Component {
    state = {
        length: 0,
        capacity: 0
    };

    componentDidMount() {
        var UPDATE_TOPIC = 'update';
        PubSub.subscribe(
            UPDATE_TOPIC,
            function (msg, data) {
                // Calculate
                if (data.length !== this.state.length) {
                    var capacity = 6;
                    if (data.length < 2000) capacity = 2;
                    else if (data.length < 2800) capacity = 4;
                    this.setState({ capacity: capacity, length: data.length });
                }
            }.bind(this)
        );
    }

    render() {
        return (
            <Row className={this.props.isMobile ? 'tips-mobile' : 'tips'} type="flex" justify="space-around">
                <Col className="tips-tohide">
                    <Row type="flex" className="tips-wrapper">
                        <Col span={8} className="tips-icon">
                            <img alt="" src="./capacity.svg" />
                        </Col>
                        <Col span={14}>
                            You can seat up to {this.state.capacity}
                            &nbsp;people.
                        </Col>
                    </Row>
                </Col>
                <Col className="tips-tohide">
                    <Row type="flex" className="tips-wrapper">
                        <Col span={8} className="tips-icon">
                            <img alt="" src="./factory.svg" />
                        </Col>
                        <Col span={14}>Made in 4 to 6 weeks.</Col>
                    </Row>
                </Col>
                <Col className="tips-tohide">
                    <Row type="flex" className="tips-wrapper">
                        <Col span={8} className="tips-icon">
                            <img alt="" src="./plywood.svg" />
                        </Col>
                        <Col span={14}>Birch plywood with soft finish.</Col>
                    </Row>
                </Col>
                <Col className="tips-toshow">
                    You can seat
                    <br />
                    up to {this.state.capacity}&nbsp;people.
                </Col>
            </Row>
        );
    }
}

export default Tips;
