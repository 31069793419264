import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Radio, Row, Col, Switch } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Feature extends React.Component {
    onChangeSwitch = (active) => {
        this.props.cb(active ? 1 : 0);
    };

    onChangeRadio = (e) => {
        this.props.cb(parseInt(e.target.value));
    };

    componentDidMount() {}

    createOptions = () => {
        let options = [];
        options.push(
            <RadioButton key="1" value="1" disabled={this.props.disabledFirst}>
                {this.props.list[1]}
            </RadioButton>
        );
        options.push(
            <RadioButton key="2" value="2" disabled={parseInt(this.props.tableLength) < 200}>
                {this.props.list[2]}
            </RadioButton>
        );
        return options;
    };

    render() {
        return (
            <Row>
                <Col span={6}>
                    <Switch
                        style={{
                            marginTop: '4px',
                            paddingTop: '1px'
                        }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.props.value > 0}
                        onChange={this.onChangeSwitch}
                    />
                </Col>
                <Col span={18}>
                    <RadioGroup
                        onChange={this.onChangeRadio}
                        value={this.props.value > 0 ? '' + this.props.value : '1'}
                        disabled={parseInt(this.props.value) === 0}
                    >
                        {this.createOptions()}
                    </RadioGroup>
                </Col>
            </Row>
        );
    }
}

export default Feature;
