import React from 'react';
import '../theme.js';
import Frames from '../components/frames.js';

class IndexPage extends React.Component {
    constructor() {
        super();
        this.state = {
            isMobile: 0
        };
    }

    componentDidMount() {
        this.handleWindowSizeChange();
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        const isMobile = window.innerWidth <= 992;
        if (isMobile !== this.state.isMobile) this.setState({ isMobile: isMobile });
    };

    render() {
        return <Frames isMobile={this.state.isMobile} />;
    }
}

export default IndexPage;
