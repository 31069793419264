/**
 * @author romain cochet
 *
 * This object is the complete Plato Table Model.
 */

import * as THREE from 'three';
import Table from './models/table.js';
import Jupe from './models/jupe.js';
import Pied from './models/pied.js';

function Plato(scene, parameters) {
    this.name = 'Plato';

    this.parameters = parameters;
    this.group = new THREE.Group();
    if (window.material === undefined) {
        window.material = {
            images: {
                wood: [new THREE.ImageLoader().load('./textures/woodsquare.jpg'), new THREE.ImageLoader().load('./textures/oak.jpg')],
                metal: new THREE.ImageLoader().load('./textures/metal.jpg'),
                accessories: {
                    'cables-only': new THREE.ImageLoader().load('./textures/cables-only.png'),
                    'plugs-only': new THREE.ImageLoader().load('./textures/plugs-only.png'),
                    'plugs-plugs': new THREE.ImageLoader().load('./textures/plugs-plugs.png'),
                    'plugs-cables': new THREE.ImageLoader().load('./textures/plugs-cables.png')
                }
            }
        };
    }

    this.textures = {
        wood: new THREE.Texture(),
        metal: new THREE.Texture()
    };

    // Create the Table
    var texture = this.textures.wood.clone();
    texture.image = window.material.images.wood[0];
    texture.needsUpdate = true;
    texture.repeat.set(1, 1);
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    this.table = new Table(new THREE.Vector3(this.parameters.width, this.parameters.length, this.parameters.thickness), texture);
    this.table.add(this.group);

    // Create the Jupe
    texture = this.textures.wood.clone();
    texture.image = window.material.images.wood[0];
    texture.needsUpdate = true;
    texture.repeat.set(1, 1);
    texture.rotation = Math.PI / 2;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    this.jupe = new Jupe(new THREE.Vector3(this.parameters.width, this.parameters.length, this.parameters.thickness), texture);
    this.jupe.add(this.group);

    // Create Pieds de Table
    this.pieds = [];
    texture = this.textures.wood.clone();
    texture.image = window.material.images.wood[0];
    texture.needsUpdate = true;
    texture.repeat.set(1, 1);
    texture.rotation = Math.PI / 2;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;

    var metal_texture = this.textures.metal.clone();
    metal_texture.image = window.material.images.metal;
    metal_texture.needsUpdate = true;
    metal_texture.repeat.set(3, 1);
    //metal_texture.rotation = Math.PI / 2;
    metal_texture.wrapS = metal_texture.wrapT = THREE.MirroredRepeatWrapping;

    // 4 Pieds
    var rotation = [-Math.PI / 2, Math.PI, Math.PI / 2, 0];
    for (var i = 0; i < 4; i++) {
        var p = new Pied(this.parameters.thickness, this.parameters.material, this.parameters.color);
        p.group.rotateZ(rotation[i]);
        p.add(this.group);
        this.pieds.push(p);
    }

    // Place everything
    this.update();

    // Rotate and add Group to Scene
    this.group.rotateX(-Math.PI / 2);
    scene.add(this.group);
}

// Change Table position
Plato.prototype.update = function () {
    // Update Table Position & Dimensions
    this.table.position.set(0, 0, this.parameters.height);
    this.table.dimensions.x = this.parameters.width;
    this.table.dimensions.y = this.parameters.length;
    /*this.table.wood.mesh.material.map.repeat.x = this.parameters.width / 1600;
    this.table.wood.mesh.material.map.repeat.y = this.parameters.length / 1600;
    this.table.wood.mesh.material.map.offset.x =
        -this.parameters.width / 1600 / 2 + 0.5;
    this.table.wood.mesh.material.map.offset.y =
        -this.parameters.length / 1600 / 2 + 0.75;*/
    this.table.gates = this.parameters.gates;
    this.table.plugs = this.parameters.plugs;
    this.table.material = this.parameters.material;
    this.table.color = this.parameters.color;
    this.table.update(this.parameters);

    // Update Jupe Position & Dimensions
    this.jupe.dimensions.x = this.parameters.width;
    this.jupe.dimensions.y = this.parameters.length;
    this.jupe.table_height = this.parameters.height;
    this.jupe.material = this.parameters.material;
    this.table.color = this.parameters.color;
    this.jupe.update();

    // Update all Pieds
    var positions = [
        [1, -1],
        [-1, -1],
        [-1, 1],
        [1, 1]
    ];
    for (var i = 0; i < 4; i++) {
        this.pieds[i].position.set((positions[i][0] * this.parameters.width) / 2, (positions[i][1] * this.parameters.length) / 2, 0);
        this.pieds[i].dimensions.x = this.parameters.height - 2;
        this.pieds[i].color = this.parameters.color;
        this.pieds[i].material = this.parameters.material;
        this.pieds[i].update();
    }
};

export default Plato;
