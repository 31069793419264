import * as THREE from 'three';
import { Vector3 } from 'three';
import Quadrilateral from './quadrilateral';

class Box {
    constructor(dimensions = new Vector3(100, 100, 100), material = 'birch', color = 0xfef1d2, angle = 45) {
        this.dimensions = dimensions.clone();
        this.angle = angle;
        this.position = new Vector3(0, 0, 0);
        this.material = material;
        this.color = color;

        let ref = { x: this.dimensions.x / 2, y: this.dimensions.y / 2, z: this.dimensions.z / 2 };

        this.planes = [
            new Quadrilateral(
                [
                    [ref.x, ref.y, ref.z],
                    [-ref.x, ref.y, ref.z],
                    [-ref.x, -ref.y, ref.z],
                    [ref.x, -ref.y, ref.z]
                ],
                this.material,
                this.color
            ),
            new Quadrilateral(
                [
                    [ref.x, ref.y, -ref.z],
                    [-ref.x, ref.y, -ref.z],
                    [-ref.x, -ref.y, -ref.z],
                    [ref.x, -ref.y, -ref.z]
                ],
                this.material,
                this.color
            ),
            new Quadrilateral(
                [
                    [ref.x, ref.y, ref.z],
                    [-ref.x, ref.y, ref.z],
                    [-ref.x, ref.y, -ref.z],
                    [ref.x, ref.y, -ref.z]
                ],
                this.material,
                this.color
            ),
            new Quadrilateral(
                [
                    [ref.x, -ref.y, ref.z],
                    [-ref.x, -ref.y, ref.z],
                    [-ref.x, -ref.y, -ref.z],
                    [ref.x, -ref.y, -ref.z]
                ],
                this.material,
                this.color
            ),
            new Quadrilateral(
                [
                    [ref.x, ref.y, ref.z],
                    [ref.x, -ref.y, ref.z],
                    [ref.x, -ref.y, -ref.z],
                    [ref.x, ref.y, -ref.z]
                ],
                this.material,
                this.color
            ),
            new Quadrilateral(
                [
                    [-ref.x, ref.y, ref.z],
                    [-ref.x, -ref.y, ref.z],
                    [-ref.x, -ref.y, -ref.z],
                    [-ref.x, ref.y, -ref.z]
                ],
                this.material,
                this.color
            )
        ];

        this.group = new THREE.Group();
        for (let quad of this.planes) {
            this.group.add(quad.mesh);
        }
    }

    addTo(container) {
        container.add(this.group);
    }

    calculateOpposite(min, ref) {
        min = min / (2 * Math.tan((this.angle * Math.PI) / 180)) + ref;
        min = min > ref - 0.000001 ? ref : min;
        min = min < 0 ? 0 : min;
        return min;
    }

    calculateReverse(min, ref) {
        return (min - ref) * 2 * Math.tan((this.angle * Math.PI) / 180);
    }

    update() {
        this.group.position.set(this.position.x, this.position.y, this.position.z);

        let index = 0;
        let refMax = { x: this.dimensions.x / 2, y: this.dimensions.y / 2, z: 0 };
        let refMin = { x: -this.dimensions.x / 2, y: -this.dimensions.y / 2, z: -this.dimensions.z };

        let minX = -refMin.x;
        let minY = -refMin.y;
        let minZ = -refMin.z;
        if (this.angle !== 90) {
            minX = this.calculateOpposite(refMin.z, refMax.x);
            minY = this.calculateOpposite(refMin.z, refMax.y);
            minZ = Math.max(this.calculateReverse(minX, refMax.x), this.calculateReverse(minY, refMax.y));
            minX = this.calculateOpposite(minZ, refMax.x);
            minY = this.calculateOpposite(minZ, refMax.y);
        }

        this.planes[index++].points = [
            [refMax.x, refMax.y, refMax.z],
            [-refMax.x, refMax.y, refMax.z],
            [-refMax.x, -refMax.y, refMax.z],
            [refMax.x, -refMax.y, refMax.z]
        ];
        this.planes[index++].points = [
            [minX, minY, minZ],
            [-minX, minY, minZ],
            [-minX, -minY, minZ],
            [minX, -minY, minZ]
        ];
        this.planes[index++].points = [
            [refMax.x, -refMax.y, refMax.z],
            [-refMax.x, -refMax.y, refMax.z],
            [-minX, -minY, minZ],
            [minX, -minY, minZ]
        ];
        this.planes[index++].points = [
            [-refMax.x, refMax.y, refMax.z],
            [-refMax.x, -refMax.y, refMax.z],
            [-minX, -minY, minZ],
            [-minX, minY, minZ]
        ];
        this.planes[index++].points = [
            [-refMax.x, refMax.y, refMax.z],
            [refMax.x, refMax.y, refMax.z],
            [minX, minY, minZ],
            [-minX, minY, minZ]
        ];
        this.planes[index++].points = [
            [refMax.x, refMax.y, refMax.z],
            [refMax.x, -refMax.y, refMax.z],
            [minX, -minY, minZ],
            [minX, minY, minZ]
        ];

        for (let plane of this.planes) {
            plane.color = this.color;
            plane.material = this.material;
            plane.update();
        }
    }
}

export default Box;
