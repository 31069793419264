import React from 'react';
import PlatoScene from './plato/scene.js';

class Viewer extends React.Component {
    constructor() {
        super();
        // Create Scene
        this.platoscene = new PlatoScene();
    }

    componentDidMount() {
        this.platoscene.create(this.threeRootElement);
        // Load elements & trigger animate when load is finished
        this.platoscene.load();

        window.addEventListener('resize', this.handleWindowSizeChange);
        window.requestAnimationFrame(
            function () {
                this.handleWindowSizeChange();
            }.bind(this)
        );
    }

    handleWindowSizeChange = () => {
        this.platoscene.clearSize();
        window.requestAnimationFrame(
            function () {
                this.platoscene.setSize(this.props.isMobile);
            }.bind(this)
        );
    };

    componentWillUnmount() {
        this.platoscene.dispose();
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    render() {
        return <div id="PlatoViewer" ref={(element) => (this.threeRootElement = element)} />;
    }
}

export default Viewer;
