import React from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Mollie from '../../../service/mollie.js';
var md = require('markdown-it')();

class PaymentMollie extends React.Component {
    state = {
        checkout: null,
        visible: false
    };

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false
        });
    };

    componentDidMount() {
        let mollie = new Mollie();
        mollie.newOrder(this.props.cart, this.props.infos).then(
            function (data) {
                if (data && data.href) {
                    this.setState({ checkout: data.href });
                }
            }.bind(this)
        );
    }

    terms() {
        fetch('/conditions.md')
            .then((response) => response.text())
            .then(function (result) {
                var content = md.render(result);
                Modal.info({
                    title: null,
                    centered: false,
                    style: { top: '60px', width: '100%' },
                    width: '95%',
                    maskClosable: true,
                    closable: true,
                    icon: null,
                    content: <div dangerouslySetInnerHTML={{ __html: content }} />,
                    onOk() {}
                });
            });
    }

    render() {
        const { checkout } = this.state;
        const { update, infos } = this.props;

        return (
            <div>
                <p>Mollie is our partnership to handle online payment. It is 100% secured and easy to use.</p>
                <p>
                    <Checkbox name="terms" onChange={update}>
                        I accept{' '}
                        <Button style={{ padding: '0px' }} type="link" onClick={this.terms}>
                            Terms &amp; Conditions
                        </Button>
                    </Checkbox>
                </p>
                <Button
                    style={{ marginTop: '10px' }}
                    block={true}
                    type={checkout && infos.terms ? 'primary' : 'disabled'}
                    icon={<CheckOutlined />}
                    href={checkout && infos.terms ? checkout : null}
                >
                    Pay with Mollie
                </Button>
            </div>
        );
    }
}

export default PaymentMollie;
