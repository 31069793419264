/**
 * @author romain cochet
 */

import * as THREE from 'three';
import Box from './base/box.js';

function Jupe(dimensions, material = 'birch', color = 0xfef1d2) {
    this.name = 'Jupe';
    // Save parameters
    this.position = new THREE.Vector3(0, 0, 0);
    this.dimensions = dimensions;
    this.table_height = 0;
    this.material = material;
    this.color = color;

    this.group = new THREE.Group();

    // Create Jupe, part 1
    this.p1 = new Box(new THREE.Vector3(dimensions.x, 75, dimensions.z), this.material, this.color);
    this.p1.group.rotateX(Math.PI / 2);

    // Create Jupe, part 2
    this.p2 = new Box(new THREE.Vector3(dimensions.x, 75, dimensions.z), this.material, this.color);
    this.p2.group.rotateX(Math.PI / 2);
    this.p2.group.rotateY(Math.PI);

    // Create Jupe, part 3
    this.p3 = new Box(new THREE.Vector3(dimensions.x, 75, dimensions.z), this.material, this.color);
    this.p3.group.rotateX(Math.PI / 2);
    this.p3.group.rotateY(Math.PI / 2);

    // Create Jupe, part 4
    this.p4 = new Box(new THREE.Vector3(dimensions.x, 75, dimensions.z), this.material, this.color);
    this.p4.group.rotateX(Math.PI / 2);
    this.p4.group.rotateY(-Math.PI / 2);

    this.group.add(this.p1.group, this.p2.group, this.p3.group, this.p4.group);
}

// Add wood to parent
Jupe.prototype.add = function (parent) {
    parent.add(this.group);
};

// Change Jupe position
Jupe.prototype.update = function () {
    this.group.position.set(this.position.x, this.position.y, -75 / 2 + this.table_height);

    this.p1.position.set(0, -this.dimensions.y / 2, 0);
    this.p2.position.set(0, this.dimensions.y / 2, 0);
    this.p3.position.set(this.dimensions.x / 2, 0, 0);
    this.p4.position.set(-this.dimensions.x / 2, 0, 0);

    let index = 0;
    for (let p of [this.p1, this.p2, this.p3, this.p4]) {
        p.dimensions.set((index++ < 2 ? this.dimensions.x : this.dimensions.y) - 150, 75, this.dimensions.z);
        p.material = this.material;
        p.color = this.color;
        p.update();
    }
};

export default Jupe;
