import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';

class Entry extends React.Component {
    render() {
        return (
            <Col
                style={{
                    display: !this.props.isActive && this.props.isMobile ? 'none' : 'block'
                }}
            >
                <Row
                    type="flex"
                    style={{
                        height: '100%'
                    }}
                >
                    <Col
                        span={5}
                        style={{
                            display: this.props.isMobile ? 'block' : 'none'
                        }}
                    >
                        <Button onClick={this.props.cb.bind(null, -1)} type={this.props.position === 'first' ? '' : 'primary'}>
                            <LeftOutlined />
                        </Button>
                    </Col>
                    <Col
                        className="plato-label"
                        span={this.props.isMobile ? 14 : 6}
                        style={{
                            textAlign: this.props.isMobile ? 'center' : 'left'
                        }}
                    >
                        {this.props.name}
                    </Col>
                    <Col
                        span={5}
                        style={{
                            textAlign: 'right',
                            display: this.props.isMobile ? 'block' : 'none'
                        }}
                    >
                        <Button onClick={this.props.cb.bind(null, 1)} type={this.props.position === 'last' ? '' : 'primary'}>
                            <RightOutlined />
                        </Button>
                    </Col>
                    <Col
                        style={{
                            minHeight: this.props.isMobile ? '4em' : 'auto',
                            paddingTop: this.props.isMobile ? '1em' : 0
                        }}
                        span={this.props.isMobile ? 24 : 18}
                    >
                        {this.props.children}
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default Entry;
