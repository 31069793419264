/**
 * @author romain cochet
 */

import * as THREE from 'three';
import Box from './base/box.js';

function Table(dimensions, texture) {
    this.name = 'Table';
    // Save parameters
    this.position = new THREE.Vector3(0, 0, 0);
    this.dimensions = dimensions;
    this.group = new THREE.Group();
    this.plugs = 0;
    this.gates = 0;
    this.material = 'birch';

    // Create Table
    this.wood = new Box(dimensions);
    this.group.add(this.wood.group);

    // Fake Shadow from the table
    var shadowGeo = new THREE.PlaneGeometry(dimensions.x * 2, dimensions.y * 2);
    var shadowMap = new THREE.TextureLoader().load('./textures/shadow.png');
    var shadowMat = new THREE.MeshBasicMaterial({
        map: shadowMap,
        side: THREE.DoubleSide,
        opacity: 1
    });
    shadowMat.transparent = true;
    shadowMat.depthWrite = true;

    this.shadowMesh = new THREE.Mesh(shadowGeo, shadowMat);
    this.shadowMesh.translateZ(-1);
    this.group.add(this.shadowMesh);

    // PLUGS & GATES
    this.accessories = [];

    var plane = new THREE.PlaneGeometry(400, 400);
    for (let conf of [
        { r: 0, t: 'plugs-only' },
        { r: 1 / 2, t: 'plugs-only' },
        { r: -1 / 2, t: 'plugs-plugs' }
    ]) {
        let texture = new THREE.Texture();
        texture.anisotropy = 16;
        let mesh = new THREE.Mesh(
            plane,
            new THREE.MeshStandardMaterial({
                map: texture,
                transparent: true,
                roughness: 0.8,
                opacity: 1
            })
        );
        this.group.add(mesh);
        this.accessories.push({
            ratio: conf.r,
            mesh,
            texture: conf.t
        });
    }
}

// Add wood to parent
Table.prototype.add = function (parent) {
    parent.add(this.group);
};

Table.prototype.updateAccessories = function (parameters) {
    const config = [
        { p: 0, g: 0, c: null, s: null },
        { p: 1, g: 0, c: 'plugs-only', s: null },
        { p: 0, g: 1, c: 'cables-only', s: null },
        { p: 1, g: 1, c: 'plugs-cables', s: null },
        { p: 2, g: 0, c: 'plugs-plugs', s: null },
        { p: 0, g: 2, c: null, s: 'cables-only' },
        { p: 1, g: 2, c: 'plugs-only', s: 'cables-only' },
        { p: 2, g: 2, c: 'plugs-plugs', s: 'cables-only' }
    ];
    config.map(function (conf) {
        if (conf.p === parameters.plugs && conf.g === parameters.gates) {
            for (let obj of this.accessories) {
                if (obj.ratio === 0) {
                    if (conf.c === null) {
                        obj.mesh.visible = false;
                    } else {
                        obj.mesh.visible = true;
                        obj.texture = conf.c;
                    }
                } else {
                    if (conf.s === null) {
                        obj.mesh.visible = false;
                    } else {
                        obj.mesh.visible = true;
                        obj.texture = conf.s;
                    }
                }
                obj.mesh.position.set(0, (this.dimensions.y / 2) * obj.ratio, this.position.z + 1);
                if (obj.texture !== obj.saved) {
                    obj.mesh.material.map.image = window.material.images.accessories[obj.texture];
                    obj.mesh.material.needsUpdate = true;
                    obj.mesh.material.map.needsUpdate = true;
                    obj.saved = obj.texture;
                }
            }
        }
    }.bind(this));
};

// Change Table position
Table.prototype.update = function (parameters) {
    this.wood.position.set(this.position.x, this.position.y, this.position.z);
    this.wood.dimensions.set(this.dimensions.x, this.dimensions.y, this.dimensions.z);
    this.wood.material = this.material;
    this.wood.update();
    this.updateAccessories(parameters);

    this.shadowMesh.geometry.attributes.position.array[3 * 0 + 0] = (-this.dimensions.x / 2) * 2.6;
    this.shadowMesh.geometry.attributes.position.array[3 * 0 + 1] = (this.dimensions.y / 2) * 1.795;
    this.shadowMesh.geometry.attributes.position.array[3 * 1 + 0] = (this.dimensions.x / 2) * 2.6;
    this.shadowMesh.geometry.attributes.position.array[3 * 1 + 1] = (this.dimensions.y / 2) * 1.795;
    this.shadowMesh.geometry.attributes.position.array[3 * 2 + 0] = (-this.dimensions.x / 2) * 2.6;
    this.shadowMesh.geometry.attributes.position.array[3 * 2 + 1] = (-this.dimensions.y / 2) * 1.795;
    this.shadowMesh.geometry.attributes.position.array[3 * 3 + 0] = (this.dimensions.x / 2) * 2.6;
    this.shadowMesh.geometry.attributes.position.array[3 * 3 + 1] = (-this.dimensions.y / 2) * 1.795;
    this.shadowMesh.geometry.attributes.position.needsUpdate = true;
    this.shadowMesh.material.opacity = 0.7 + (1 - this.position.z / 1100) / 2;
};

export default Table;
