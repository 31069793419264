/**
 * @author romain cochet
 */

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import PubSub from 'pubsub-js';

import Plato from './plato.js';

global.maxAnisotropy = 16;

function PlatoScene() {}

PlatoScene.prototype.create = function (container) {
    this.backgroundColor = 0xe3e7e3;
    this.ambientColor = 0xafafaf;
    this.kill = false;
    this.isMobile = null;
    this.container = container;
    this.animation = {
        from: {
            phi: 10,
            theta: 45
        },
        to: {
            phi: 65,
            theta: 45
        },
        last: {
            phi: 90,
            theta: 90
        },
        steps: 90,
        key: 90,
        timeout: 0,
        userInt: false
    };
    this.animation_target = {
        from: 0,
        to: 380,
        steps: 90,
        key: 0,
        timeout: 0
    };

    // All parameters for the scene.
    this.parameters = {
        width: 1300,
        length: 2600,
        height: 760,
        thickness: 18,
        color: '#f2fefb',
        gates: 2,
        plugs: 2,
        viewer: 'startup'
    };

    // Scene
    this.scene = new THREE.Scene();

    // Camera
    this.camera = new THREE.PerspectiveCamera({}, 1.1, 10, 250000);
    this.camera.alpa = true;
    this.camera.setFocalLength(90);
    this.camera.layers.enable(0);
    this.camera.layers.disable(1);
    this.camera.layers.disable(2);
    this.scene.add(this.camera);

    // Abmient Light
    this.al = new THREE.AmbientLight(this.ambientColor);
    this.al.intensity = 1.36;
    this.scene.add(this.al);

    // Spotlight to simulate the shadow
    this.light = new THREE.SpotLight(0xffffff, 0.74, 16500, Math.PI / 8);
    this.light.position.set(-3500, 4000, -7000);
    this.light.castShadow = false;
    this.scene.add(this.light);

    // Add reflexion on metal under table
    this.pointlight = new THREE.PointLight(0xffffff, 0.35, 9000);
    this.pointlight.position.set(0, 30, 0);
    this.pointlight.castShadow = false;
    this.scene.add(this.pointlight);

    // Renderer settings
    this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        premultipliedAlpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(10, 10);
    this.container.appendChild(this.renderer.domElement);
    global.maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();

    if (true) {
        this.renderer_snapshot = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            premultipliedAlpha: true,
            preserveDrawingBuffer: true
        });
        this.renderer_snapshot.setPixelRatio(window.devicePixelRatio);
        this.renderer_snapshot.setSize(75, 65);

        this.camera_snapshot = new THREE.PerspectiveCamera({}, 1, 10, 250000);
        this.camera_snapshot.alpa = true;
        this.camera_snapshot.aspect = 75 / 65;
        this.camera_snapshot.setFocalLength(90);
        this.camera_snapshot.layers.enable(0);
        this.camera_snapshot.layers.disable(1);
        this.camera_snapshot.layers.disable(2);
        this.camera_snapshot.position.setFromSphericalCoords(9860, THREE.Math.degToRad(75), THREE.Math.degToRad(45));
        this.camera_snapshot.lookAt(0, 300, 0);
        this.camera_snapshot.updateProjectionMatrix();
        this.scene.add(this.camera_snapshot);
    }

    // Add controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI / 2.04;
    this.controls.minAzimuthAngle = -Math.PI / 3;
    this.controls.maxAzimuthAngle = Math.PI / 2;
    this.controls.enableDamping = false;
    this.controls.autoRotate = false;
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    //this.controls.enabled = false;
    this.controls.autoRotateSpeed = 0.15;
    this.controls.minDistance = 3000;
    this.controls.maxDistance = 25000;
    this.camera.position.setFromSphericalCoords(15860, THREE.Math.degToRad(90), THREE.Math.degToRad(90));

    this.controls.addEventListener(
        'start',
        function (...args) {
            this.animation.userInt = true;
        }.bind(this)
    );

    this.controls.addEventListener(
        'end',
        function () {
            this.animation.userInt = false;
            let s = this.getSpherical(this.camera.position.x, this.camera.position.y, this.camera.position.z);
            this.animation.from.phi = s.phi;
            this.animation.from.theta = s.theta;
            this.animation.last.phi = s.phi;
            this.animation.last.theta = s.theta;
            this.animation.timeout = 180;
        }.bind(this)
    );

    // Manage HTML controls
    this.plugControls();
};

// Rendering loop
PlatoScene.prototype.animate = function () {
    if (this.kill === false) {
        this.renderer.render(this.scene, this.camera);

        if (this.animation.userInt === false && this.animation.key > 0) {
            this.animation.key--;
            // During animation, we check if the controls is at the right place.
            let ctrl = document.getElementById('PlatoCtrl').getBoundingClientRect();
            if (ctrl && ctrl.x > 0 && this.isMobile === true) {
                this.clearSize();
                window.requestAnimationFrame(
                    function () {
                        this.setSize(this.isMobile);
                    }.bind(this)
                );
            }
            //
            this.controls.enabled = false;
            this.animation.last.phi =
                (this.animation.to.phi - this.animation.from.phi) * Math.cos(-(Math.PI / 2) * (this.animation.key / this.animation.steps)) +
                this.animation.from.phi;
            this.animation.last.theta =
                (this.animation.to.theta - this.animation.from.theta) * Math.cos(-(Math.PI / 2) * (this.animation.key / this.animation.steps)) +
                this.animation.from.theta;
            this.camera.position.setFromSphericalCoords(15860, THREE.Math.degToRad(this.animation.last.phi), THREE.Math.degToRad(this.animation.last.theta));
            this.camera.updateProjectionMatrix();
            if (this.animation.key === 0) {
                this.controls.enabled = true;
                if (this.parameters.viewer === 'startup') {
                    this.parameters.viewer = 'default';
                }
            }
        } else if (this.animation.userInt === false && this.animation.timeout > 0) {
            this.animation.timeout--;
            if (this.animation.timeout === 0) {
                this.parameters.viewer = 'default';
                this.animateTo(65, 45, 180, 0);
            }
        }
        if (this.animation_target.key > 0) {
            let r = this.animation_target.key / this.animation_target.steps;
            this.controls.target.set(0, r * this.animation_target.from + (1 - r) * this.animation_target.to, 0);
            this.animation_target.key--;
            if (this.animation_target.key === 0) {
                this.controls.target.set(0, this.animation_target.to, 0);
            }
        }
    }
    this.controls.update();
    requestAnimationFrame(this.animate.bind(this));
};

PlatoScene.prototype.load = function () {
    THREE.DefaultLoadingManager.onStart = function (url, itemsLoaded, itemsTotal) {};

    THREE.DefaultLoadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {};

    THREE.DefaultLoadingManager.onLoad = function () {
        this.animate();
        PubSub.publishSync('viewer.ready');
    }.bind(this);

    THREE.DefaultLoadingManager.onError = function (url) {};

    // Create Plato Model
    this.plato = new Plato(this.scene, this.parameters);

    this.update();
    this.renderer.compile(this.scene, this.camera);
    if (true) {
        this.renderer_snapshot.compile(this.scene, this.camera_snapshot);
    }
};

PlatoScene.prototype.dispose = function () {
    this.kill = true;

    // We have to handle everything
    this.scene = null;
    this.al = null;
    this.light = null;
    this.pointlight = null;
    this.renderer = null;
};

PlatoScene.prototype.clearSize = function () {
    this.renderer.setSize(10, 10);
};
PlatoScene.prototype.setSize = function (isMobile) {
    this.isMobile = isMobile;
    this.camera.aspect = this.container.offsetWidth / (this.container.offsetHeight - 2);
    if (isMobile) this.camera.zoom = this.container.offsetWidth / this.container.offsetHeight + 0.3;
    else this.camera.zoom = this.container.offsetWidth / this.container.offsetHeight;
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight - 2);
    this.camera.updateProjectionMatrix();
};

PlatoScene.prototype.animateTo = function (phi, theta, steps, timeout) {
    this.animation.from = {
        phi: this.animation.last.phi,
        theta: this.animation.last.theta
    };
    this.animation.to = {
        phi: phi,
        theta: theta
    };
    this.animation.key = steps;
    this.animation.steps = steps;
    this.animation.timeout = timeout;
    if (timeout === undefined) {
        this.animation.timeout = 90;
    }
};

PlatoScene.prototype.plugControls = function () {
    // Update Model
    PubSub.subscribe(
        'update',
        function (msg, data) {
            var needsUpdate = false;
            for (var key in data) {
                if (this.parameters[key] !== data[key]) {
                    this.parameters[key] = data[key];
                    needsUpdate = true;
                }
            }
            if (needsUpdate) {
                this.update();
            }
        }.bind(this)
    );

    PubSub.subscribe(
        'snapshot.start',
        function (msg, data) {
            let strMime = 'image/png';
            this.renderer_snapshot.render(this.scene, this.camera_snapshot);
            PubSub.publishSync('snapshot.ready', {
                data: this.renderer_snapshot.domElement.toDataURL(strMime),
                index: data.index
            });
        }.bind(this)
    );

    // Update View
    PubSub.subscribe(
        'view',
        function (msg, data) {
            if (this.plato.parameters.viewer !== data.mode) {
                this.plato.parameters.viewer = data.mode;
                switch (data.mode) {
                    case 'length':
                        this.animateTo(75, 90, 45);
                        break;
                    case 'width':
                        this.animateTo(75, 0, 45);
                        break;
                    case 'height':
                        this.animateTo(65, 45, 45);
                        break;
                    case 'cables':
                        this.animateTo(0.001, 90, 45);
                        break;
                    case 'plugs':
                        this.animateTo(0.001, 90, 45);
                        break;
                    case 'color':
                        this.animateTo(85, 65, 45);
                        break;
                    default:
                        break;
                }
                this.controls.update();
            }
        }.bind(this)
    );
};

PlatoScene.prototype.update = function () {
    this.plato.parameters = this.parameters;
    // Camera based on plato
    if (this.animation_target.to !== this.parameters.height / 2) {
        this.animation_target.from = this.animation_target.to;
        this.animation_target.to = this.parameters.height / 2;
        this.animation_target.key = 45;
        this.animation_target.steps = 45;
    }
    this.plato.update();
};

PlatoScene.prototype.getSpherical = function (x, y, z) {
    let obj = {};
    obj.radius = Math.sqrt(x * x + y * y + z * z);
    if (obj.radius === 0) {
        obj.theta = 0;
        obj.phi = 0;
    } else {
        obj.theta = (Math.atan2(x, z) * 180) / Math.PI;
        obj.phi = (Math.acos(Math.max(-1, Math.min(1, y / obj.radius))) * 180) / Math.PI;
    }
    return obj;
};

export default PlatoScene;
