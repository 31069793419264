import React from 'react';
import { Row, Col, Radio } from 'antd';
import Price from '../../configurator/price.js';
import Mollie from '../../../service/mollie.js';

import { CountryRegionData } from '../../countries';

class Payment extends React.Component {
    state = {
        methods: null
    };

    componentDidMount() {
        // Create Method
        let mollie = new Mollie();
        mollie.getMethods(this.props.cart, this.props.infos).then(
            function (data) {
                if (data) {
                    for (let i in data) {
                        let ticket = Price.prototype.getTicket(this.props.cart, this.props.infos);
                        if (parseInt(data[i].maximumAmount.value * 100) > parseInt(ticket.total.ttc * 100)) {
                            this.props.update('payment_method', data[i].id);
                            break;
                        }
                    }
                    this.setState({ methods: data });
                }
            }.bind(this)
        );
    }

    render() {
        const { update, cart, infos } = this.props;
        const { methods } = this.state;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px'
        };

        // Create Ticket
        let ticket = Price.prototype.getTicket(cart, infos);

        return (
            <div>
                <Row style={{ marginBottom: '25px' }}>
                    <Col span={12}>
                        <strong>Billing</strong>
                        <br />
                        {infos.givenName} {infos.familyName}
                        <br />
                        {infos.company}
                        <br />
                        {infos.billing_streetAndNumber}
                        <br />
                        {infos.billing_city}, {infos.billing_postalCode}
                        <br />
                        {CountryRegionData.map(function (item, i) {
                            if (item.shortCode === infos.billing_country) {
                                return item.name;
                            }
                            return null;
                        })}
                    </Col>
                    <Col span={12}>
                        <strong>Shipping</strong>
                        <br />
                        {infos.givenName} {infos.familyName}
                        <br />
                        {infos.company}
                        <br />
                        {infos.shipping_streetAndNumber}
                        <br />
                        {infos.shipping_city}, {infos.shipping_postalCode}
                        <br />
                        {CountryRegionData.map(function (item, i) {
                            if (item.shortCode === infos.shipping_country) {
                                return item.name;
                            } else {
                                return null;
                            }
                        })}
                    </Col>
                </Row>

                {ticket && (
                    <div style={{ marginBottom: '30px' }}>
                        {ticket.products.map(function (item, i) {
                            return (
                                <Row key={i} style={{ marginBottom: '5px' }}>
                                    <Col span={15}>
                                        {item.quantity} x Table ({item.unitprice} €)
                                    </Col>
                                    <Col span={9} style={{ textAlign: 'right' }}>
                                        {item.ht} €HT
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row style={{ marginTop: '0px' }}>
                            <Col span={15}>Delivery</Col>
                            <Col span={9} style={{ textAlign: 'right' }}>
                                {ticket.delivery.ht} €HT
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '15px' }}>
                            <Col span={16} style={{ textAlign: 'right' }}>
                                HT:
                                <br />
                                VAT <em>({(Price.prototype.getVatRate(infos) * 100).toFixed(2)}%)</em>:
                                <br />
                                <strong>TOTAL:</strong>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                {ticket.total.ht} €
                                <br />
                                {ticket.total.vat} €
                                <br />
                                <strong>{ticket.total.ttc} €</strong>
                            </Col>
                        </Row>
                    </div>
                )}

                <p>Select your payment method:</p>
                <Radio.Group name="payment_method" onChange={update} value={infos.payment_method}>
                    {methods &&
                        methods.map(function (item, i) {
                            let disabled = !(parseInt(methods[i].maximumAmount.value * 100) > parseInt(ticket.total.ttc * 100));
                            return (
                                <Radio style={radioStyle} value={item.id} key={i} disabled={disabled}>
                                    <img src={item.image.size2x} alt="{ item.description}" width={'24px'} />
                                    &nbsp;&nbsp;&nbsp;
                                    {item.description}
                                    {disabled && <em>&nbsp;&nbsp;&nbsp;Only for payments &lt;{parseInt(item.maximumAmount.value)}€.</em>}
                                </Radio>
                            );
                        })}
                </Radio.Group>
            </div>
        );
    }
}

export default Payment;
