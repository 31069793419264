/**
 * @author romain cochet
 */

import * as THREE from 'three';
import Box from './base/box.js';

function Pied(thickness, material = 'birch', color = 0xf2fefb) {
    this.name = 'Pied';
    // Save parameters
    this.width = 75;
    this.position = new THREE.Vector3(0, 0, 0);
    this.dimensions = new THREE.Vector3(685, this.width, thickness);

    this.material = material;
    this.color = color;
    this.group = new THREE.Group();

    // Create Pied, part 1
    this.p1 = new Box(this.dimensions, this.material, this.color);
    this.p1.group.rotateY(Math.PI / 2);
    // Create Pied, part 2
    this.p2 = new Box(this.dimensions, this.material, this.color);
    this.p2.group.rotateY(-Math.PI / 2);
    this.p2.group.rotateX(-Math.PI / 2);

    // Create Capot, part 1
    this.c1 = new Box(this.dimensions, 'metal', this.color);
    this.c1.group.rotateY(-Math.PI / 2);
    // Create Capot, part 2
    this.c2 = new Box(this.dimensions, 'metal', this.color);
    this.c2.group.rotateY(-Math.PI / 2);
    this.c2.group.rotateX(Math.PI / 2);

    this.group.add(this.p1.group, this.p2.group, this.c1.group, this.c2.group);
}

// Add wood to parent
Pied.prototype.add = function (parent) {
    parent.add(this.group);
};

// Change Pied position
Pied.prototype.update = function () {
    this.group.position.set(this.position.x, this.position.y, this.position.z);

    this.p1.dimensions.set(this.dimensions.x, this.dimensions.y, this.dimensions.z);
    this.p1.position.set(-this.dimensions.z / 2 + this.dimensions.z / 2, -this.dimensions.y / 2, this.dimensions.x / 2);
    this.p1.material = this.material;
    this.p1.update();

    this.p2.dimensions.set(this.dimensions.x, this.dimensions.y, this.dimensions.z);
    this.p2.position.set(-this.dimensions.y / 2, -this.dimensions.z / 2 + this.dimensions.z / 2, this.dimensions.x / 2);
    this.p2.material = this.material;
    this.p2.update();

    this.c1.dimensions.set(this.dimensions.x, this.dimensions.y - 3, this.dimensions.z);
    this.c1.position.set(-this.width, -this.dimensions.y / 2 - 1.4, this.dimensions.x / 2);
    this.c1.color = this.color;
    this.c1.update();

    this.c2.dimensions.set(this.dimensions.x, this.dimensions.y - 3, this.dimensions.z);
    this.c2.position.set(-this.dimensions.y / 2 - 1.4, -this.width, this.dimensions.x / 2);
    this.c2.color = this.color;
    this.c2.update();
};

export default Pied;
