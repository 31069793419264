import React from 'react';

import { Input } from 'antd';
import Address from './address.js';
import Price from '../../configurator/price.js';

class BillingShipping extends React.Component {
    componentDidMount() {
        for (let i in this.refs) {
            for (let j in this.refs[i].refs) {
                this.refs[i].refs[j].focus();
                break;
            }
            break;
        }
    }

    next() {
        for (let i in this.refs) {
            if (this.refs[i].refs !== undefined) {
                for (let j in this.refs[i].refs) {
                    if (!this.props.infos[j]) {
                        this.refs[i].refs[j].focus();
                        return null;
                    }
                }
            } else {
                if (!this.props.infos[i]) {
                    this.refs[i].focus();
                    return null;
                }
            }
        }
        this.props.next();
    }

    render() {
        const { update, infos } = this.props;

        return (
            <div>
                <p>Thank you{infos.givenName ? ' ' + infos.givenName : ''},</p>

                <h3>
                    <span role="img" aria-label="Truck">
                        🚚
                    </span>
                    Shipping
                </h3>
                <Address ref={'shipping'} update={update} infos={infos} next={this.next.bind(this)} prefix="shipping_" />

                <h3 style={{ paddingTop: '20px' }}>
                    <span role="img" aria-label="Invoice">
                        📄
                    </span>
                    Billing
                </h3>
                <Address ref={'billing'} update={update} infos={infos} next={this.next.bind(this)} prefix="billing_" />

                {Price.prototype.isVATNumberApplicable(infos) && (
                    <div style={{ paddingTop: '20px' }}>
                        <Input
                            ref="vatnumber"
                            style={{ width: '100%' }}
                            name="vatnumber"
                            placeholder="VAT Number"
                            onChange={update}
                            value={infos['vatnumber']}
                            onPressEnter={this.next.bind(this)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default BillingShipping;
