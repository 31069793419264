import React from 'react';
import { Button, Row, Col, Badge } from 'antd';
import { ShoppingCartOutlined, PlusOutlined } from '@ant-design/icons';
import Div100vh from 'react-div-100vh';
import PubSub from 'pubsub-js';

import Seo from './seo';
import Controls from './configurator/controls.js';
import Price from './configurator/price.js';
import Tips from './configurator/tips.js';
import Viewer from './viewer/viewer.js';
import Cart from './cart.js';
import Logo from './logo.js';

class Frames extends React.Component {
    state = {
        count: 0,
        loaded: false
    };

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        if (window && document) {
            if (document.readyState === 'complete') this.handleLoad();
            else window.addEventListener('load', this.handleLoad);
        }
    }

    handleLoad() {
        this.setState({ loaded: true });
    }

    onAddToCart = (e) => {
        var EVENT_TOPIC = 'event';
        PubSub.publishSync(EVENT_TOPIC, { name: 'cart' });
        PubSub.publishSync('cart.view');
    };

    onViewToCart = (e) => {
        PubSub.publishSync('cart.view');
    };

    renderViewer() {
        if (this.state.loaded === true) return <Viewer isMobile={this.props.isMobile} />;
        else return '';
    }

    renderActions() {
        if (this.state.count) {
            return (
                <Row type="flex">
                    <Col span={4}>
                        <Button onClick={this.onViewToCart} block={true}>
                            <Badge count={this.state.count} offset={[8, 0]}>
                                <ShoppingCartOutlined style={{ fontSize: '22px' }} />
                            </Badge>
                        </Button>
                    </Col>
                    <Col span={18} offset={2}>
                        <Button block={true} type="primary" onClick={this.onAddToCart}>
                            Add To Cart
                        </Button>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row type="flex">
                    <Col span={24}>
                        <Button block={true} icon={<PlusOutlined />} type="primary" onClick={this.onAddToCart}>
                            Add To Cart
                        </Button>
                    </Col>
                </Row>
            );
        }
    }

    render() {
        if (this.state.loaded !== true) {
            return (
                <>
                    <Seo />
                    <div className="plato-logo">
                        <Logo />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Seo />
                    <Div100vh>
                        <Row
                            type="flex"
                            className={this.props.isMobile ? 'mobile' : 'desktop'}
                            style={{
                                height: '100%',
                                flexDirection: this.props.isMobile ? 'column' : 'row',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Col
                                id="main-area"
                                style={{
                                    flexGrow: 1,
                                    flexBasis: 1
                                }}
                            >
                                <Row
                                    type="flex"
                                    style={{
                                        width: this.props.isMobile ? '100%' : '66.6666666vw',
                                        height: '100%',
                                        flexDirection: 'column',
                                        flexWrap: 'nowrap'
                                    }}
                                >
                                    <Col order={1} className="plato-logo">
                                        <Logo />
                                        <Cart
                                            isMobile={this.props.isMobile}
                                            updateCount={function (count) {
                                                if (this.state.count !== count) this.setState({ count });
                                            }.bind(this)}
                                        />
                                    </Col>
                                    <Col
                                        order={this.props.isMobile ? 3 : 2}
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: 1
                                        }}
                                    >
                                        {this.renderViewer()}
                                    </Col>
                                    <Col order={this.props.isMobile ? 2 : 3}>
                                        <Row
                                            type="flex"
                                            style={{
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Col span={this.props.isMobile ? 11 : 24} offset={this.props.isMobile ? 1 : 0}>
                                                <Tips isMobile={this.props.isMobile} />
                                            </Col>
                                            <Col
                                                className="price-mobile"
                                                style={{
                                                    textAlign: 'right',
                                                    display: this.props.isMobile ? 'block' : 'none'
                                                }}
                                                span={this.props.isMobile ? 11 : 24}
                                            >
                                                <Price />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                style={{
                                    boxShadow: this.props.isMobile ? 'none' : '0 0 20px rgba(0, 0, 0, 0.2)',
                                    flexGrow: this.props.isMobile ? 0 : 1
                                }}
                            >
                                <Row
                                    type="flex"
                                    style={{
                                        height: '100%',
                                        flexDirection: 'column',
                                        flexWrap: 'nowrap'
                                    }}
                                >
                                    <Col
                                        style={{
                                            display: this.props.isMobile ? 'none' : 'block'
                                        }}
                                    >
                                        <Row
                                            className="plato-side-top"
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <Col span={this.props.isMobile ? 24 : 12} style={{ paddingLeft: '1.5em' }}>
                                                THIS PLATO
                                            </Col>
                                            <Col className="price" span={this.props.isMobile ? 24 : 12}>
                                                <Price />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col style={{ flexGrow: 1, flexBasis: this.props.isMobile ? 'auto' : 1 }}>
                                        <Controls isMobile={this.props.isMobile} />
                                    </Col>
                                    <Col className="actions" style={this.props.isMobile ? { height: '65px', paddingBottom: '10px' } : {}}>
                                        {this.renderActions()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Div100vh>
                </>
            );
        }
    }
}

export default Frames;
