import React from 'react';
import { Input } from 'antd';

class Contact extends React.Component {
    componentDidMount() {
        for (let i in this.refs) {
            this.refs[i].focus();
            break;
        }
    }

    next() {
        for (let i in this.refs) {
            if (!this.props.infos[i]) {
                this.refs[i].focus();
                return null;
            }
        }
        this.props.next();
    }

    render() {
        const { update, infos } = this.props;

        return (
            <div>
                <p>We're happy to have you here!</p>
                <p>To proceed your order, we need more informations.</p>

                <Input ref="email" name="email" placeholder="E-mail" value={infos.email} onChange={update} onPressEnter={this.next.bind(this)} />
                <Input
                    ref="givenName"
                    name="givenName"
                    placeholder="First Name"
                    value={infos.givenName}
                    onChange={update}
                    onPressEnter={this.next.bind(this)}
                />
                <Input
                    ref="familyName"
                    name="familyName"
                    placeholder="Family Name"
                    value={infos.familyName}
                    onChange={update}
                    onPressEnter={this.next.bind(this)}
                />
                <Input ref="company" name="company" placeholder="Company" value={infos.company} onChange={update} onPressEnter={this.next.bind(this)} />
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                    <em>If you want to save your order and get contacted by our team, just click "Get a Quote".</em>
                </p>
            </div>
        );
    }
}

export default Contact;
