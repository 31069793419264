import * as THREE from 'three';

class Quadrilateral {
    constructor(points, material = 'birch', color = 0xfef1d2) {
        const [pt1, pt2, pt3, pt4] = points;
        this.points = points;
        this.material = material;
        this.color = color;

        // Geometry
        this.geometry = new THREE.BufferGeometry();
        const vertices = [];
        const uvs = [];

        vertices.push(...pt1, ...pt2, ...pt3, ...pt1, ...pt3, ...pt4, ...pt1, ...pt3, ...pt2, ...pt3, ...pt1, ...pt4);
        uvs.push(0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 0, 1, 1, 1, 0, 0, 1, 0);
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        this.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        this.geometry.computeVertexNormals();

        // Texture
        this.texture = new THREE.Texture();
        this.texture.image = window.material.images.wood[1];
        this.texture.repeat.set(1, 1);
        this.texture.rotation = Math.PI / 2;
        this.texture.wrapS = this.texture.wrapT = THREE.MirroredRepeatWrapping;
        this.texture.needsUpdate = true;

        // Material
        if (this.material === 'metal') {
            // Create Material
            this.mat = new THREE.MeshPhysicalMaterial({
                color: this.color,
                emissive: this.color,
                emissiveIntensity: -0.2,
                roughness: 0.45,
                metalness: 0.15,
                reflectivity: 0,
                flatShading: true,
                clearcoat: 0.2,
                clearcoatRoughness: 0.27,
                transmission: 0,
                precision: 'highp'
            });
        } else {
            this.mat = new THREE.MeshPhongMaterial({
                color: '#FEF1D2',
                specular: 0x000000,
                shininess: 25,
                map: this.texture
            });
        }

        // Mesh
        this.mesh = new THREE.Mesh(this.geometry, this.mat);
        this.mesh.receiveShadow = true;
        this.mesh.castShadow = true;
    }

    update() {
        const [pt1, pt2, pt3, pt4] = this.points;
        const newPos = [...pt1, ...pt2, ...pt3, ...pt1, ...pt3, ...pt4, ...pt1, ...pt3, ...pt2, ...pt3, ...pt1, ...pt4];
        for (let i in this.geometry.attributes.position.array) {
            this.geometry.attributes.position.array[i] = newPos[i];
        }
        this.geometry.attributes.position.needsUpdate = true;

        if (this.material !== 'metal' && this.material !== this.materialCache) {
            this.materialCache = this.material;
            this.mesh.material.map.image = window.material.images.wood[this.material === 'birch' ? 0 : 1];
            this.mesh.material.needsUpdate = true;
            this.mesh.material.map.needsUpdate = true;
        } else if (this.material === 'metal' && this.color !== this.colorCache) {
            this.colorCache = this.color;
            this.mesh.material.color.set(this.color);
            this.mesh.material.needsUpdate = true;
        }
    }
}

export default Quadrilateral;
